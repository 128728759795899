.loginWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 101vh;
  width: 100vw;
  flex-direction: column;
  animation: backgroundFadeIn 3s ease-out forwards;
  background: linear-gradient(135deg, var(--third-color) 0%, var(--primary-color) 100%);
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.usernameWrapper, .passwordWrapper {
  display: flex;
  align-items: center;
  border: solid white 1px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
}

input {
  background-color: transparent;
  border: 0;
  margin-left: 10px;
  color: white;
  width: 100%;
}



input::placeholder {
  color: white;
}
input:focus {
  outline: none; /* Remove the default browser outline */
  box-shadow: none; /* Remove any additional box-shadow */
}

input:focus, ::-ms-reveal {
  outline: none;
  filter: invert(100%);
}

.loginButton {
  background: transparent;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  border: solid white 1px;
  cursor: pointer;
  padding: 10px;
  width: 150px;
  transition: 0.3s;
  box-sizing: border-box;
}

.loginButton:hover {
  transform: scale(1.05) rotateY(5deg);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.loginModal, .closeModalButton {
  width: 200px;
  color: white;
  background: var(--third-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
}

.closeModalButton:hover {
  background: var(--primary-color);
  color: white;
}

.loginTitle {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 40px;
  animation: flipInY 1s ease-out;
}

@media screen and (min-width: 768px) {
  .loginTitle {
    font-size: 3rem;
  }
}

.newLogo {
  width: 300px;
  height: auto;
  margin-bottom: 20px;
  animation: slideInFromLeft 1s ease-out forwards;
}

.projectTitle {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
  text-align: center;
  animation: slideInFromRight 1s ease-out forwards;
  animation-delay: 1s;
}

.projectSubtitle {
  font-size: 1.2rem;
  color: white;
  font-style: italic;
  margin-bottom: 40px;
  text-align: center;
  animation: slideUpFadeIn 1s ease-out forwards;
  animation-delay: 2s;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  width: 100vw; /* Change to 100vw to ensure full width */
  z-index: 1;
  border-top: 5px solid rgb(102, 182, 102);
  border-bottom: 5px solid rgb(102, 182, 102);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 20px; /* Add space below the footer */
}

.projectDescription {
  font-size: 0.7rem;
  color: rgb(102, 182, 102);
  text-align: center;
  width: 80%;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 20px; /* Add space above the project description */
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.footerLogo1 {
  width: 140px; /* Adjust width as necessary */
  height: auto;
}
.footerLogo2{
  width: 80px;
  height: auto;
  margin: 10px;
}
.footerLogo3 {
  width: 80px;
  height: auto;
  margin: 10px;
}

@keyframes backgroundFadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUpFadeIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  to {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

.copyright {
  color: white;
  text-align: center;
  width: 100%;
  z-index: 0;
  margin-bottom: 10px;
  position: fixed;
  bottom: 5px;
  left: 0;
}
