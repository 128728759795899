:root {
  --third-color: rgb(102, 182, 102);
}
@media (prefers-color-scheme: light) {
  :root {
    --primary-color: #ffffff;
    --secondary-color: #212121;
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #212121;
    --secondary-color: #ffffff;
  }
}
#root {
  height: 100%;
}
html,
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
*::-webkit-scrollbar {
  display: none;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.accordion {
  overflow: hidden;
  width: 100%;
}
.accordion__button {
  padding: 10px 0 10px 10px !important;
  font-size: 0.8rem;
}
.contentStyles {
  margin-left: 40px;
  margin-top: 10px;
  height: 100%;
}
.icon-white {
  color: var(--primary-color) !important;
}
.leaflet-bar a,
.leaflet-control-attribution {
  background: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}
.leaflet-tooltip {
  border: solid var(--third-color) 1px !important;
  background: var(--third-color) !important;
  color: var(--primary-color) !important;
}
.leaflet-tooltip-left::before {
  border-left-color: var(--third-color) !important;
}
.leaflet-tooltip-right::before {
  border-right-color: var(--third-color) !important;
}
.rdrCalendarWrapper,
.rdrDateDisplayWrapper,
.rdrDayDisabled {
  background: var(--primary-color) !important;
}
.rdrDay:not(.rdrDayDisabled):not(.rdrDayPassive) .rdrDayNumber span,
.rdrMonthAndYearPickers select {
  color: var(--secondary-color) !important;
}
.rdrDateInput input {
  color: var(--third-color) !important;
}
.rdrNextPrevButton {
  background: transparent !important;
}

.rdrPprevButton i {
  border-color: transparent var(--third-color) transparent transparent !important;
}
.rdrNextButton i {
  border-color: transparent transparent transparent var(--third-color) !important;
}
.rdrEndEdge,
.rdrStartEdge,
.rdrInRange {
  color: var(--third-color) !important;
}
.rdrDayNumber > span::after {
  background: var(--secondary-color) !important;
}
.rdrDateInput {
  color: var(--primary-color) !important;
  border: solid var(--third-color) 1px !important;
  background: var(--primary-color) !important;
}
.rdrMonthAndYearPickers {
  color: var(--third-color) !important;
}
.rdrCalendarWrapper {
  font-size: 10px !important;
}
.rdrDateDisplay {
  flex-direction: column;
  align-items: center;
}
.rdrMonth {
  width: 18em !important;
}
.rdrDateDisplay > * {
  width: 60% !important;
  margin-left: 0 !important;
  margin-bottom: 10px;
}
.ReactModal__Overlay {
  background-color: #21212133 !important;
}

.active {
  background: var(--primary-color);
  color: var(--third-color) !important;
}
@media screen and (min-width: 300px) {
  .rdrMonth {
    width: 25em !important;
  }
  .accordion__button {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .rdrCalendarWrapper {
    font-size: 14px !important;
  }
}
