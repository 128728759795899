body {
  font-family: "Montserrat", sans-serif;
}

.homepageWrapper {
  display: flex;
  flex-direction: column;
  background: url('slicica1.jpg') no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding-bottom: 200px; /* Space for footer and copyright */
}

.homepageWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3); /* Adjust the last number for different opacity levels */
  z-index: -1;
}

.header {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.logoutButton {
  user-select: none;
  transition: all 0.3s ease;
  padding: 10px 20px; /* Adjust padding for better size */
  display: inline-flex; /* Use inline-flex for button alignment */
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Helvetica Neue', sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, var(--primary-color) 40%, var(--third-color) 100%); /* Matching the navigation button style */
  color: white;
  border: none; /* Remove default button border */
  cursor: pointer; /* Cursor pointer to indicate button */
  font-size: 1rem; /* Adjust font size */
}

.logoutButton:hover {
  background: linear-gradient(145deg, #4ea857, #59a27d); /* Slightly change the gradient on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Add a bit more shadow on hover */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.content {
  margin-top: 60px; /* Space for the fixed header */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 200px; /* Space for footer */
}

.content img,
.maps .pond {
  width: 80%; /* Maintain width relative to parent */
  height: auto; /* Set height to auto to maintain aspect ratio */
  max-height: 400px; /* Limit the height to match the DeviceMap */
  object-fit: contain; /* Ensures the whole image is visible */
  margin: 20px auto; /* Center the image within its container and add spacing */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Optional shadow for depth */
  border-radius: 8px; /* Optional rounded corners */
}

.maps {
  height: 60vh;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .content img,
  .maps .pond {
    width: 95%; /* Allow the image and map to take more width on small screens */
    max-height: 300px; /* Adjust the maximum height for smaller screens */
  }
}

.maps .pond {
  width: 80%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
  margin: 20px auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

/* Styles for navigation buttons */
.navigationButton {
  user-select: none;
  transition: all 0.3s ease;
  margin: 10px; /* Adjusted for better spacing */
  padding: 10px;
  min-height: 50px; /* Adjust height for better fit */
  display: inline-flex; /* Use inline-flex for button alignment */
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Helvetica Neue', sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, var(--primary-color) 40%, var(--third-color) 100%);
  color: white;
  border: none; /* Remove default button border */
  cursor: pointer; /* Cursor pointer to indicate button */
  font-size: 1rem; /* Adjust font size */
}

/* Active button style */
.navigationButton.active {
  background: linear-gradient(145deg, #090b09, #000000);
  font-weight: bold; /* Make the active button text bold */
}

/* Container for navigation buttons */
.navigationButtons {
  display: flex;
  justify-content: center; /* Center the buttons */
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.customButton {
  user-select: none;
  transition: all 0.3s ease;
  padding: 10px;
  height: 40px; /* Adjusted height for better button proportion */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Helvetica Neue', sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, var(--primary-color) 40%, var(--third-color) 100%);
  color: white;
  border: none; /* Remove border for flat design */
  cursor: pointer;
  margin-right: 20px; /* Provide spacing if next to other elements */
}

.customButton:hover {
  background: linear-gradient(145deg, #4ea857, #59a27d);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  position: absolute; /* Positioning footer above the text */
  bottom: 8%; /* Positioning footer above the bottom */
  width: 100%;
  height: 80px;
  z-index: 1;
  border-top: 5px solid rgb(102, 182, 102);
  border-bottom: 5px solid rgb(102, 182, 102); /* Light grey border at the top */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 0 0 10px 10px;
}

.footer1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: white;
  position: absolute; /* Positioning footer above the text */
  bottom: 0; /* Positioning footer above the bottom */
  width: 100%;
  height: auto;
  z-index: 1;
  /* Light grey border at the top */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 0 0 10px 10px;
}

.projectDescription {
  font-size: 0.7rem;
  color: rgb(102, 182, 102); /* Changed to white */
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 80%;
  font-style: bold;
}

.footerImage2 {
  width: 140px; /* Adjust width as necessary */
  height: auto; /* Ensure consistent height */
}

.footerImage1 {
  width: 70px; /* Adjust width as necessary */
  height: auto; /* Ensure consistent height */
}

.footerImage3 {
  width: 70px; /* Adjust width as necessary */
  height: auto; /* Ensure consistent height */
  margin-right: 30px;
  margin-bottom: 5px;
}

/* Ensure footer is always at the bottom */
.homepageWrapper {
  position: relative;
  min-height: 100vh; /* Footer at the bottom for short pages */
  padding-bottom: 200px; /* Space for footer */
}

/* Content should have padding at the bottom to make space for footer */
.content {
  flex: 1; /* Allow content to grow and take available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 20px; /* Height of the footer */
}

@media screen and (max-width: 768px) {
  .content img {
    width: 95%; /* Allow the image to take more width on small screens */
    max-height: 300px; /* Adjust the maximum height for smaller screens */
  }
  .maps .pond {
    width: 95%; /* Allow the map to take more width on small screens */
    max-height: 300px; /* Adjust the maximum height for smaller screens */
  }
}

.maps {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.deviceMainDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.deviceMainDiv > span {
  margin: 20px 0;
}

.deviceInnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

@media screen and (min-width: 350px) {
  .pond {
    font-size: 1rem;
  }
}

@media screen and (min-width: 450px) {
  .mapWrapper {
    width: 300px;
    height: 300px;
  }
  .pond {
    width: 400px;
    height: 400px;
  }
  .sparklineWrapper {
    width: 120px;
  }
}

@media screen and (min-width: 768px) {
  .homepageWrapper {
    flex-direction: row;
  }
}

.copyright {
  background-color: white;
  color: black;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
  margin-top: 20px; /* Space above the copyright text */
  padding: 10px 0;
  border-top: 2px solid #dcdcdc; /* Light grey border at the top */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Dummy text to be displayed below the footer */
.dummyText {
  text-align: center;
  color: white;
  margin-top: 20px;
}
