.latestDataTop {
  user-select: none;
  transition: all 0.3s ease;
  margin: 20px;
  padding: 10px;
  height: 100px; /* Fixed height as provided */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Helvetica Neue', sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, var(--primary-color) 30%, var(--third-color) 100%); /* Example gradient */
  color: white;
  flex: 0 0 150px; /* flex-grow, flex-shrink, flex-basis */
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box; /* Ensure padding doesn't affect the overall dimensions */
  position: relative; /* Add relative positioning */
  overflow: hidden; /* Ensure content doesn't overflow */
  text-align: center; /* Center text */
}

.icon {
  position: absolute;
  top: 12px; /* Adjust as needed */
  left: 15px; /* Adjust as needed */
  font-size: 30px; /* Adjust as needed */
  color: white; /* Adjust icon color if needed */
}

.latestDataTop span {
  font-size: 1rem; /* Adjust font size */
  margin-right: 2px;
  transition: color 0.3s ease;
}

.latestDataTop:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

/* Add responsive behavior */
@media (max-width: 768px) {
  .latestDataTop {
    margin: 10px;
    padding: 10px;
    flex-basis: calc(50% - 20px); /* Adjust flex-basis for smaller screens, minus the margin */
  }
  .latestDataTop span {
    font-size: 0.9rem; /* Smaller font size for mobile */
  }
}

@media (max-width: 600px) {
  .latestDataTop {
    margin: 10px;
    padding: 10px;
    flex-basis: calc(100% - 20px); /* Adjust flex-basis for smaller screens, minus the margin */
  }
  .latestDataTop span {
    font-size: 0.8rem; /* Smaller font size for mobile */
  }
}

.latestDataWrapper {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}

.dropdownWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.dropdownLabel {
  margin-bottom: 10px;
  color: var(--third-color);
  margin-right: 10px;
  text-align: center;
}

.timeDropdown {
  background-color: var(--third-color);
  color: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  border: 1px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  max-width: 150px;
}

.chartsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.chartsWrapper > div {
  border: solid black 1px;
  height: 300px;
  margin: 5px;
  width: 80%;
}

@media screen and (min-width: 450px) {
  .latestDataTop span {
    font-size: 1.2rem;
  }
  .latestDataTop {
    height: 90px;
    flex: 0 0 300px;
    flex-direction: row;
  }
  .chartsWrapper > div {
    width: 320px;
  }
  .dropdownLabel {
    font-size: 14px;
  }
}

@media screen and (min-width: 500px) {
  .chartsWrapper > div {
    width: 440px;
  }
  .dropdownLabel {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .latestDataTop {
    flex: 0 0 400px;
  }
  .latestDataTop span {
    font-size: 1.5rem;
  }
  .chartsWrapper > div {
    width: 300px;
  }
  .chartsWrapper {
    flex-direction: row;
  }
  .dropdownLabel {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .dropdownWrapper {
    flex-direction: column;
  }
  .dropdownLabel {
    margin-bottom: 10px;
  }
}

.tableWrapper {
  justify-content: center;
  align-items: center;
}

.tableWrapper {
  height: 70vh;
  width: 90%;
  overflow: auto;
  border: solid var(--third-color) 1px;
}

.tableWrapper > table {
  text-align: center;
  height: 100%;
  width: 100%;
  color: var(--third-color);
  border-collapse: collapse;
}

.dateSubmitBtn {
  border: solid var(--third-color) 1px;
  background: transparent;
  padding: 10px 20px;
  color: var(--third-color);
  cursor: pointer;
  margin: 15px 0;
  transition: 0.3s;
}

.dateSubmitBtn:hover {
  background: var(--third-color);
  color: var(--primary-color);
  transform: scale(0.95);
}

.tableWrapper > table td,
.tableWrapper > table th {
  white-space: nowrap;
  border: solid var(--third-color) 1px;
  border-collapse: collapse;
  padding: 10px;
}

.dateWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--primary-color);
  border: solid var(--third-color) 1px;
  margin-top: 10;
  padding: 5px;
}

.tableWrapper > table th {
  position: sticky;
  top: 0;
  left: 0;
  background: var(--third-color);
  color: var(--primary-color);
}

.btnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.csvButton {
  min-width: 180px;
  margin-top: 20px;
  padding: 10px;
  border: solid var(--third-color) 1px;
  background: var(--primary-color);
  color: var(--third-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.csvButton:hover {
  color: var(--primary-color);
  background: var(--third-color);
  transform: scale(0.95);
}

.csvButton span {
  margin-left: 10px;
}

.noData {
  color: var(--third-color);
  font-size: 1.5rem;
  text-align: center;
}

.selectionDiv,
.mainWrapperDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selectionDiv {
  padding-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .dateWrapper {
    padding: 40px;
  }
  .noData {
    font-size: 2rem;
  }
}

.csvButton span input {
  color: var(--third-color);
  width: 4em;
}

.csvButton:hover span input {
  color: var(--primary-color);
}

.selectionDiv select {
  background: transparent;
  border: solid var(--third-color) 1px;
  padding: 10px;
  margin: 5px 0;
  color: var(--third-color);
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
  max-width: 150px;
}

.loadedEntries {
  margin: 20px 20px 0 0;
  text-align: center;
  color: var(--third-color);
}

.highlight {
  transition: 0.5s;
  background-color: var(--third-color);
  color: var(--primary-color) !important;
  padding: 3px;
}

.noHighlight {
  background: transparent;
  transition: 0.5s;
}
